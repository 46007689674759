/*
  续租/换铺详情（共用）

    此组件有四个状态：
    换铺详情查看：/finance/changeStore/viewDetail
    换铺详情调整：/finance/changeStore/editDetail
    续租详情查看：/finance/continueRent/viewDetail
    续租详情调整：/finance/continueRent/editDetail
*/

<template>
  <div>
    <div>
      <!-- 头部 -->
      <div style="margin: -45px -24px 0px">
        <a-card :bordered="false">
          <DescriptionList title="商户信息" size="large">
            <div style="margin-top: 16px">
              <Description term="门店编号">
                <label>{{ tenantInfo.facilityCode }}</label>
              </Description>
              <Description term="门店名称">
                <label>{{ tenantInfo.facilityName }}</label>
              </Description>
              <Description term="档口编号">
                <label>{{ tenantInfo.kitchenCode }}</label>
              </Description>
              <Description term="店铺名称">
                <label>{{ tenantInfo.storeName }}</label>
              </Description>
              <Description term="客户名称">
                <label>{{ tenantInfo.tenantName }}</label>
              </Description>
              <Description term="客户合同">
                <span class="blueColor">
                  <a @click="viewContractDetail">{{
                    tenantInfo.contractCode
                  }}</a>
                </span>
              </Description>
            </div>
          </DescriptionList>
        </a-card>
      </div>
      <!-- tab切换 -->
      <div style="margin: -24px -24px 24px">
        <a-card
          style="width: 100%"
          :bordered="false"
          :tabList="tabList"
          :activeTabKey="activeTabKey"
          @tabChange="(key) => onTabChange(key)"
        >
          <ChangeStoreDetailDeposit
            v-if="activeTabKey === 'deposit'"
            :billId="queryInfo.billId"
            :status="status"
          />
          <ChangeStoreDetailBalance
            v-if="activeTabKey === 'balance'"
            :billId="queryInfo.billId"
            :status="status"
          />
          <ChangeStoreDetailSummary
            v-if="activeTabKey === 'summary'"
            :billId="queryInfo.billId"
            :status="status"
          />
          <div v-if="status === 'edit'" class="button-panel">
            <a-button
              v-if="activeTabKey !== 'summary'"
              type="primary"
              style="margin-left: 12px"
              @click="nextTab"
              >下一步</a-button
            >
            <a-button
              v-if="activeTabKey !== 'deposit' && activeTabKey !== 'summary'"
              @click="previousTab"
              >上一步</a-button
            >
            <a-popconfirm
              placement="topRight"
              title="确认发送这些数据么？"
              :disabled="status === 'view' || hasSent"
              @confirm="confirmSendBill"
              okText="Yes"
              cancelText="No"
            >
              <a-button
                style="margin-left: 8px"
                type="primary"
                :disabled="status === 'view' || hasSent"
                v-if="activeTabKey === 'summary'"
                :loading="loadingSend"
                :icon="hasSent ? 'check' : ''"
                >{{ hasSent? '已发送' : '发送账单' }}</a-button
              >
            </a-popconfirm>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hasPermissions } from "@/utils/auth";
import { FacilityActions } from "@/utils/actions";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
  
import ChangeStoreDetailDeposit from "./changeStoreDetailDeposit";
import ChangeStoreDetailBalance from "./changeStoreDetailBalance";
import ChangeStoreDetailSummary from "./changeStoreDetailSummary";

export default {
  name: "ChangeStoreDetail",
  components: {
    DescriptionList,
    Description,
    ChangeStoreDetailDeposit,
    ChangeStoreDetailBalance,
    ChangeStoreDetailSummary,
  },
  props: {},
  data() {
    return {
      // 查看/编辑状态
      status: this.$route.path.endsWith("viewDetail") ? "view" : "edit",
      // 换铺/续租功能类型
      featureType: this.$route.path.includes("changeStore")
        ? "changeStore"
        : "continueRent",
      // 路由获取
      queryInfo: {
        billId: this.$route.query.id,
      },
      tenantInfo: {
        facilityCode: this.$route.query.facilityCode,
        facilityName: this.$route.query.facilityName,
        kitchenCode: this.$route.query.kitchenCode,
        tenantName: this.$route.query.tenantName,
        storeName: this.$route.query.storeName,
        contractCode: this.$route.query.contractCode,
        contractId: this.$route.query.contractId,
      },
      // tab页
      tabList: [
        {
          key: "deposit",
          tab: "押金类",
        },
        {
          key: "balance",
          tab: "商户余额",
        },
        {
          key: "summary",
          tab: "总计",
        },
      ],
      // 当前展示的tab
      activeTabKey: "deposit",
      // 发送账单的loading
      loadingSend: false,
      // 是否已发送
      hasSent: false,
    };
  },
  created() {
    this.refetch();
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
  },
  filters: {},
  methods: {
    refetch() {
      // 初始化
    },
    onTabChange(key) {
      this.activeTabKey = key;
    },
    // 跳转到下一个tab
    nextTab() {
      const currentIndex = this.tabList.findIndex((t) => {
        return t.key === this.activeTabKey;
      });
      this.activeTabKey =
        currentIndex >= 0
          ? this.tabList[currentIndex + 1].key
          : this.tabList[0].key;
    },
    // 跳转到上一个tab
    previousTab() {
      const currentIndex = this.tabList.findIndex((t) => {
        return t.key === this.activeTabKey;
      });
      this.activeTabKey =
        currentIndex >= 0
          ? this.tabList[currentIndex - 1].key
          : this.tabList[0].key;
    },
    // 发送账单
    confirmSendBill() {
      this.loadingSend = true;
      axios({
        url:   this.$baseUrl + "accountDeposit/send",
        method: "GET",
        params: {
          accountDepositId: this.queryInfo.billId,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.success("发送成功");
            this.hasSent = true;
          } else {
            this.$message.error(res.data.returnMsg);
          }
          this.loadingSend = false;
        })
        .catch((error) => {
          this.loadingSend = false;
          this.$message.error(error.message);
        });
    },
    viewContractDetail() {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: this.tenantInfo.contractId },
      });
    },
  },
};
</script>
<style lang="less" >
.button-panel {
  display: flex;
  flex-direction: row-reverse;
  margin: 12px;
}
.total-bold {
  font-weight: bold;
}
</style>