/*
    换铺/续租详情 - 总计tab
*/
<template>
  <div>
    <a-spin :spinning="dataLoading">
        <a-table
          rowKey="id"
          class="table-wrapper"
          :columns="columns"
          :dataSource="detailList"
          :pagination="false"
          :rowClassName="handleTableRowClass"
        />
    </a-spin>
  </div>
</template>

<script>
import _get from "lodash/get";
import { decimalTwoDigit } from "@/utils/regex";
  

export default {
  name: "SettlementDetailSummary",
  components: {
  },
  props: {
    // 页面状态：查看/编辑
    status: {
      type: String,
      default: "view",
    },
    billId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      // 表格数据
      detailList: [],
      // 表格中的列
      columns: [
        {
          title: "收款类",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "金额（元）",
          align: "right",
          dataIndex: "totalAmount",
          key: "totalAmount",
        },
      ],
      // 数据加载
      dataLoading: false,
    };
  },
  methods: {
    refetch() {
      this.dataLoading = true;
      axios({
        url:   this.$baseUrl + "accountDeposit/queryTotalDetail",
        method: "GET",
        params: {
          accountDepositId: this.billId,
        },
      }).then((res) => {
        if (res.data.success) {
          let listData = _get(res, "data.obj.detailList") || [];
          this.detailList = [
            ...listData.map(d=> ({...d, id: Math.random()})),
            {
              id: -1,
              name: "总计（元）",
              totalAmount: _get(res, "data.obj.totalAmount"),
            },
          ];
        } else {
          this.$message.error(res.data.returnMsg);
        }
        this.dataLoading = false;
      });
    },
    // 表格最后一行加粗
    handleTableRowClass(record, index) {
      if (record.name && record.name.includes("总计")) {
        return "total-bold";
      }
    },
  },
  created() {
    this.refetch();
  },
};
</script>

<style scoped>
.button-panel {
  display: flex;
  flex-direction: row-reverse;
  margin: 12px;
}
.nob {
  border-radius: 0 !important;
}
.table-wrapper {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  width: 600px;
}
.desc-title {
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
}
</style>