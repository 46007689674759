/*
    换铺/续租详情 - 押金类tab
*/
<template>
  <div>
    <a-spin :spinning="dataLoading">
      <div class="button-panel">
        <div style="flex: 1">
          <a-icon
            v-if="discountInfo"
            style="color: orange; margin-right: 8px"
            theme="filled"
            type="exclamation-circle"
          />
          <span style="color: orange">{{ discountInfo }}</span>
        </div>
        <a-button v-if="status !== 'view'" icon="plus" @click="handleAdd"
          >新增</a-button
        >
      </div>
      <a-table
        rowKey="id"
        :columns="columns"
        :dataSource="depositList"
        :pagination="false"
        :rowClassName="handleTableRowClass"
      >
        <div slot="action" slot-scope="text, record">
          <span v-if="record.id !== -1" class="blueColor">
            <a @click="handleEdit(record)">编辑</a>&nbsp;
            <a-popconfirm
              v-if="record.canDelete === 'Y'"
              placement="bottomRight"
              title="确认删除吗？"
              @confirm="handleDelete(record)"
              okText="Yes"
              cancelText="No"
            >
              <a>删除</a>
            </a-popconfirm>
          </span>
        </div>
      </a-table>
    </a-spin>
    <a-modal
      :title="isNew ? '新增押金' : '编辑押金'"
      :width="450"
      :visible="showModal"
      @ok="onSubmit"
      @cancel="onCancel"
      destroyOnClose
    >
      <a-form
        :form="form"
        layout="inline"
        :labelCol="formLabelCol"
        :wrapperCol="formInputCol"
      >
        <a-form-item label="押金类型">
          <a-select
            v-if="isNew"
            placeholder="选择一个选项"
            v-decorator="[
              'costType',
              {
                rules: [{ required: true, message: '请输入押金类型' }],
                initialValue: depositItem.costType,
              },
            ]"
            @change="
              (val) => {
                handleDepositItemTypeChange(val);
              }
            "
          >
            <a-select-option
              v-for="type in this.depositTypeList"
              :key="type.typeId"
              >{{ type.typeName }}</a-select-option
            >
          </a-select>
          <div v-else>{{ getTypeNameById(depositItem.typeId) }}</div>
        </a-form-item>
        <a-form-item
          label="押金类型名称"
          v-if="canEditTypeName(depositItem.typeId)"
        >
          <a-input
            placeholder="请输入名称"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ],
                initialValue: depositItem.name,
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="已收金额">
          <a-input
            placeholder="请输入金额"
            v-model="depositItem.payedAmount"
            v-decorator="[
              'payedAmount',
              {
                rules: [
                  {
                    pattern: decimalTwoDigit,
                    required: true,
                    message: '不支持的格式',
                  },
                ],
                initialValue: depositItem.payedAmount,
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="已退金额">
          <a-input
            placeholder="请输入金额"
            v-model="depositItem.returnAmount"
            v-decorator="[
              'returnAmount',
              {
                rules: [
                  {
                    pattern: decimalTwoDigit,
                    required: true,
                    message: '不支持的格式',
                  },
                ],
                initialValue: depositItem.returnAmount,
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="剩余金额">
          <a-input
            disabled
            placeholder="请输入金额"
            v-model="modalRemainAmount"
          ></a-input>
        </a-form-item>
        <a-form-item label="平移金额">
          <a-input
            placeholder="请输入金额"
            v-decorator="[
              'chargeAmount',
              {
                rules: [
                  {
                    pattern: decimalTwoDigit,
                    required: true,
                    message: '不支持的格式',
                  },
                ],
                initialValue: depositItem.chargeAmount,
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            placeholder="请输入备注"
            class="nob"
            v-decorator="[
              'remark',
              {
                rules: [{ required: true, message: '请输入备注' }],
                initialValue: depositItem.remark,
              },
            ]"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { decimalTwoDigit } from "@/utils/regex";
  
import { billStatus } from "@/objects/businessObjects";

let newDepositItem = {
  id: null,
  costType: null,
  name: null,
  payedAmount: null,
  returnAmount: null,
  chargeAmount: null,
  remark: null,
};

export default {
  name: "ChangeStoreDetailDeposit",
  props: {
    // 页面状态：查看/编辑
    status: {
      type: String,
      default: "view",
    },
    // 账单id
    billId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      // 表格数据
      depositList: [],
      // 数据加载
      dataLoading: false,
      // 表格中的列。没有根据status去判断查看或编辑，通过隐藏action让查看态不可编辑(注意数组会被filter)
      columns: [
        {
          title: "押金类型",
          dataIndex: "costType",
          key: "costType",
          customRender: (value, row, index) => {
            let name = null;
            if (row.name) {
              name = row.name;
            } else {
              const s = this.depositTypeList.find((s) => s.typeId === value);
              name = s && s.typeName;
            }
            return name;
          },
        },
        {
          title: "已收金额（元）",
          dataIndex: "payedAmount",
          key: "payedAmount",
        },
        {
          title: "已退回金额（元）",
          dataIndex: "returnAmount",
          key: "returnAmount",
        },
        {
          title: "剩余金额（元）",
          dataIndex: "remainAmount",
          key: "remainAmount",
        },
        {
          title: "平移金额（元）",
          dataIndex: "chargeAmount",
          key: "chargeAmount",
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ].filter((c) => this.status === "edit" || c.dataIndex !== "action"),
      // 折扣信息
      discountInfo: "",
      showModal: false,
      // 当前正在编辑的押金项
      depositItem: newDepositItem,
      // 新增（true）/编辑（false）
      isNew: null,
      // 编辑金额时的表单
      form: this.$form.createForm(this),
      // 押金种类
      depositTypeList: [],
      // 两位小数的正则
      decimalTwoDigit,
      // 表单label的样式
      formLabelCol: {
        style: {
          textAlign: "right",
          width: "110px",
          margin: "5px",
        },
      },
      // 表单input的样式
      formInputCol: {
        style: {
          width: "250px",
          margin: "5px",
        },
      },
    };
  },
  methods: {
    refetch() {
      // 押金列表
      this.dataLoading = true;
      axios({
        url:   this.$baseUrl + "accountDeposit/queryDepositDetail",
        method: "GET",
        params: {
          id: this.billId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.depositList = [
            ...(res.data.obj.depositDetailList || []),
            {
              id: -1,
              name: "总计（元）",
              payedAmount: res.data.obj.totalPayedAmount,
              returnAmount: res.data.obj.totalReturnAmount,
              remainAmount: res.data.obj.totalRemainAmount,
              chargeAmount: res.data.obj.totalChargeAmount,
            },
          ];
          this.discountInfo = res.data.obj.discountInfo;
        } else {
          this.$message.error(res.data.returnMsg);
        }
        this.dataLoading = false;
      });
    },
    // 新增金额
    handleAdd() {
      this.depositItem = Object.assign({}, newDepositItem);
      this.isNew = true;
      this.showModal = true;
    },
    // 编辑金额
    handleEdit(record) {
      this.depositItem = record;
      this.isNew = false;
      this.showModal = true;
    },
    // 删除金额
    handleDelete(record) {
      axios({
        url:   this.$baseUrl + "accountDeposit/deleteDepositDetail",
        method: "GET",
        params: {
          id: record.id,
        },
      }).then((res) => {
        if (res.data.success) {
          this.$message.success("删除成功");
          this.refetch();
        } else {
          this.$message.error(res.data.returnMsg);
        }
      });
    },
    // 新增/编辑的提交
    onSubmit() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 已收金额 - 已退金额 = 剩余金额
          if (Number(values.payedAmount) < Number(values.returnAmount)) {
            console.log(`values:`, values);
            this.$message.error("已收金额”不能小于“已退金额”！");
            return;
          }

          if (this.isNew) {
            axios({
              url:   this.$baseUrl + "accountDeposit/addDepositDetail",
              method: "POST",
              data: {
                accountDepositId: this.billId,
                costType: values.costType,
                name: values.name,
                payedAmount: values.payedAmount,
                returnAmount: values.returnAmount,
                chargeAmount: values.chargeAmount,
                remark: values.remark,
              },
            }).then((res) => {
              if (res.data.success) {
                this.$message.success("添加成功");
                this.refetch();
              } else {
                this.$message.error(res.data.returnMsg);
              }
            });
          } else {
            // edit
            axios({
              url:   this.$baseUrl + "accountDeposit/updateDepositDetail",
              method: "POST",
              data: {
                id: this.depositItem.id,
                costType: values.costType,
                name: values.name,
                payedAmount: values.payedAmount,
                returnAmount: values.returnAmount,
                chargeAmount: values.chargeAmount,
                remark: values.remark,
              },
            }).then((res) => {
              if (res.data.success) {
                this.$message.success("编辑成功");
                this.refetch();
              } else {
                this.$message.error(res.data.returnMsg);
              }
            });
          }
          this.showModal = false;
        } else {
          return;
        }
      });
    },
    onCancel() {
      this.showModal = false;
    },
    // 表格最后一行加粗
    handleTableRowClass(record, index) {
      if (record.name && record.name.includes("总计")) {
        return "total-bold";
      }
    },
    // 根据押金类型id查找name
    getTypeNameById(id) {
      const type = this.depositTypeList.find((t) => t.typeId === id);
      return !!type ? type.typeName : "";
    },
    canEditTypeName(id) {
      const type = this.depositTypeList.find((t) => t.typeId === id);
      return type && type.enableModifyName === "Y";
    },
    handleDepositItemTypeChange(value) {
      this.depositItem.typeId = value;
    },
  },
  computed: {
    modalRemainAmount() {
      // 这里考虑加入精确计算小数的库
      return (this.depositItem.payedAmount - this.depositItem.returnAmount).toFixed(2);
    },
  },
  created() {
    // 押金种类
    axios({
      url:   this.$baseUrl + "departure/cost/type/list",
      method: "GET",
      params: {
        type: 1, // 1:押金类， 2:赔偿类， 3:返还类
      },
    }).then((res) => {
      if (res.data.success) {
        this.depositTypeList = res.data.rows;
      } else {
        this.$message.error(res.data.returnMsg);
      }
    });
    this.refetch();
  },
};
</script>

<style lang="less" scoped>
.nob {
  border-radius: 0 !important;
}
.bold {
  font-weight: bold;
}
.button-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>