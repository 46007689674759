/*
    换铺/续租详情 - 商户余额tab
*/
<template>
  <a-spin :spinning="dataLoading">
    <div class="text-panel">商户余额：{{ totalAmount }} 元</div>
    <a-table
      rowKey="id"
      :columns="columns"
      :dataSource="historyDataList"
      :pagination="false"
    >
    </a-table>
  </a-spin>
</template>

<script>
  

export default {
  name: "ChangeStoreDetailBalance",
  props: {
    // 页面状态：查看/编辑
    status: {
      type: String,
      default: "view",
    },
    // 账单id
    billId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      // 表格数据
      historyDataList: [],
      // 表格中的列。
      columns: [
        {
          title: "时间点",
          dataIndex: "gmtCreated",
          key: "gmtCreated",
        },
        {
          title: "余额变动方式",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "余额变动金额",
          dataIndex: "amount",
          key: "amount",
        },
      ],
      // 最终余额
      totalAmount: 0,
      // 数据加载中
      dataLoading: false,
    };
  },
  methods: {
    refetch() {
      // 获取表格中的数据
      this.dataLoading = true;
      axios({
        url:   this.$baseUrl + "accountDeposit/queryAccountHistory",
        method: "GET",
        params: {
          accountDepositId: this.billId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.historyDataList = res.data.obj.historyDataList || [];
          this.totalAmount = res.data.obj.totalAmount;
        } else {
          this.$message.error(res.data.returnMsg);
        }
        this.dataLoading = false;
      });
    },
  },
  created() {
    this.refetch();
  },
};
</script>

<style scoped>
.text-panel {
  display: flex;
  margin: 12px;
  font-weight: bold;
}
.nob {
  border-radius: 0 !important;
}
</style>